var global;

$(document).on('turbolinks:load', function() {
  secureMail();
  video();
  gallery();
  files();
  heightEvents();
  magicLine();
});

$(window).resize(function() {
  heightEvents();
});

function secureMail() {
  let mail = document.getElementById('mail');

  if (mail != null) {
    let address = mail.href;
    let title = mail.textContent;
    let newAddress = address.replace('!', '').replace('&', '');
    let newTitle = title.replace('!', '').replace('&', '');

    mail.href = newAddress;
    mail.innerHTML = newTitle;
  }
}

function magicLine() {
  var $el, leftPos, newWidth, $mainNav = $(".header-menu");
  $mainNav.append("<li id='magic-line'></li>");

  if ($(".no-line").length === 0) {
    var $magicLine = $("#magic-line");

    $magicLine
      .width($(".header-menu li.active").width())
      .css("left", $(".header-menu li.active").position().left)
      .data("origLeft", $magicLine.position().left)
      .data("origWidth", $magicLine.width());

    $(".header-menu li").hover(function () {
      $el = $(this);
      leftPos = $el.position().left;
      newWidth = $el.width();
      $magicLine.stop().animate({
        left: leftPos,
        width: newWidth
      });
    }, function () {
      $magicLine.stop().animate({
        left: $magicLine.data("origLeft"),
        width: $magicLine.data("origWidth")
      });
    });
  }
}

function video() {
  $('#video').magnificPopup({
    type: 'iframe'
  });
}

function gallery() {
  $('.gallery').magnificPopup({
    type: 'image',
    gallery:{
      enabled: true
    }
  });
}

function heightEvents() {
  let height = $('.events').find('img').height();
  height === 0 ? height = 289 : height;

  $('.events > div').each(function () {
    $(this).height(height);
  });
}

function files() {
  let address = window.location.href;
  let text;

  if (address.indexOf("/fr/") > -1) {
    text = 'Parcourir'
  } else if (address.indexOf("/en/") > -1) {
    text = 'Browse'
  } else {
    text = 'Wählen'
  }

  if ($(":file").length > 0) {
    $(":file").filestyle({
      icon: false,
      buttonText: text
    });

    $('.file').each( function() {
      var placeholder = $(this).attr('placeholder');
      $(this).parent().find('input').attr('placeholder', placeholder);
    });
  }
}
