import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

$(document).on('turbolinks:load', function() {
  let items = [];

  $('.my-photoswipe .item').each(function () {
    let link = $(this).find('a'),
      item = {
        src: link.attr('href'),
        w: link.data('width'),
        h: link.data('height'),
        title: link.data('caption')
      };
    items.push(item);
  });

  let pswpElement = $('.pswp')[0];

  $('.my-photoswipe a').click(function (event) {
    event.preventDefault();

    let ind = $(this).closest('.item').index();

    window.location.hash = ind;

    let options = {
      index: ind,
      bgOpacity: 0.85,
      loop: false,
      shareEl: false,
      history: false
    };

    let gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  });
});