// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// vendor js
import 'javascripts/bootstrap'
// prev
import 'javascripts/prev/file-input.js'
import 'javascripts/prev/collapse.js'
import 'javascripts/prev/jquery.magnific-popup.js'

// photoswipe
import 'photoswipe/dist/photoswipe.js'
import 'photoswipe/src/css/main.scss'
import 'photoswipe/src/css/default-skin/default-skin.scss'
import '../js/photoswipe.js'

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import '../styles/application.sass'
import '../js/main.js'
